import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import LoginFun from '../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './Appointment.css';
import moment from "moment";

var UserData;
export default function Appointment() {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [appointment, setappointment] = useState([]);
    const logindata = LoginFun();
  
    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getAllappointment();
        }, 1000);
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getAllappointment = async () => {

        try {
            let MainUserId = localStorage.getItem("UserID");
            $('#treatmentCycleTable').DataTable().destroy();
                dispatch(setLoader(true));
                await axios(`${url}api/AppointmentDosage/GetAppointments?contactId=${MainUserId}`).then((res) => {
                    setappointment(res.data.content);
                    setTimeout(function () {
                        $("#treatmentCycleTable").DataTable();
                   }, 200);
               
                });
            dispatch(setLoader(false));
        }
        catch {
        }
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Card className=' border-0 cardcolor'>
                    {/* <Card.Header className=' colorprofile   text-light'><h5>Appointment </h5></Card.Header> */}
                    <div className='card-header mb-2'>
                        <div className="row">
                            <div className="col">
                                <h5>Appointment </h5>
                            </div>
                            <div className="col text-right">
                                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                            </div>
                        </div>
                    </div>
                    <Card.Body>
                        <Card.Text>
                            {/* step wizard for Medical History */}
                            <div className='col-md-12  '>

                                <Card.Body>
                                    <form>
                                        <div className=''>
                                            <div>
                                                <div className="col text-left bg-white-200  ">
                                                    <div className="table-responsive">
                                                        <table class="table border-0" id="treatmentCycleTable">
                                                            <thead >
                                                                <tr>
                                                                    <th>Subject</th>
                                                                    <th>Appointment Date </th>
                                                                    {/* <th>Description</th> */}
                                                                    {/* <th>Appointment Type</th>
                                                                    <th>Room</th> */}
                                                                    {/* <th>End </th> */}
                                                                    <th>Duration</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {appointment && appointment.map((listValue, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{listValue.subject}</td>
                                                                            <td>{moment(listValue.startTime).format("DD-MMM-yyyy") ? moment(listValue.startTime).format("DD-MMM-yyyy") : moment(listValue.startTime).format("DD-MMM-yyyy")}</td>
                                                                            {/* <td>{listValue.description}</td> */}
                                                                            {/* <td>{listValue.appointmentlibraryitemName}</td>
                                                                            <td>{listValue.roomName}</td> */}
                                                                            {/* <td>{moment(listValue.endTime).format("DD-MMM-yyyy") ? moment(listValue.endTime).format("DD-MMM-yyyy") : moment(listValue.endTime).format("DD-MMM-yyyy")}</td> */}
                                                                            <td>{listValue.duration}</td>
                                                                            <td>{listValue.bookingStatusName}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <BootstrapTable
                                                        bootstrap4
                                                        keyField='id'
                                                        wrapperClasses="table-responsive"
                                                        pagination={pagination}
                                                        rowEvents={tableRowEvents}
                                                        filter={filterFactory()}
                                                        data={appointment}
                                                        columns={columns}
                                                        hover
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </Card.Body>

                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}
